import React from 'react'

import { isMobile, isTablet, isBrowser } from 'react-device-detect'

import Browse from './components/browse'
import Tablet from './components/tablet'
import Mobile from './components/mobile'

const featureList = [
  {
    author: 'Ethan Miller',
    title: 'Head of Merchandising',
    commentary: 'CREAMODA is the perfect blend of creativity and sales performance.',
    type: 'sm'
  },
  {
    author: 'Emma Williams',
    title: 'Fashion Director',
    commentary: 'Their AI-driven design is phenomenal! We’ve seen a huge boost in sales because they perfectly align products with market demands and user preferences. Highly recommend!',
    type: 'lg'
  },
  {
    author: 'Sophia Martinez',
    title: 'Head of Production',
    commentary: 'CREAMODA has streamlined our entire production process. From concept to execution, we’ve seen reduced costs and flawless accuracy. It’s a game-changer for garment design.',
    type: 'lg'
  },
  {
    author: 'John Carter',
    title: 'Head of Product Development',
    commentary: "Not only do we stay ahead of the competition, but we're setting the trends that others follow.",
    type: 'sm'
  }
]

const RealStories = () => {
  return (
    <>
      {isBrowser && <Browse featureList={featureList} />}
      {isTablet && <Tablet featureList={featureList} />}
      {isMobile && !isTablet && <Mobile featureList={featureList} />}
    </>
  )
}

export default RealStories
