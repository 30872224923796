import React from 'react'

import { isMobile, isTablet, isBrowser } from 'react-device-detect'

import Browse from './components/browse'
import Tablet from './components/tablet'
import Mobile from './components/mobile'

// import Group_1 from '../../../../assets/img/Group_1.svg'
// import Group_2 from '../../../../assets/img/Group_2.svg'
// import Group_3 from '../../../../assets/img/Group_3.svg'
// import Group_4 from '../../../../assets/img/Group_4.svg'

const Group_1 = 'https://aimoda-ai.oss-us-east-1.aliyuncs.com/aimoda-homepage-image/achievement-clients.svg'
const Group_2 = 'https://aimoda-ai.oss-us-east-1.aliyuncs.com/aimoda-homepage-image/Achievement-user.svg'
const Group_3 = 'https://aimoda-ai.oss-us-east-1.aliyuncs.com/aimoda-homepage-image/achievement-clients.svg'
const Group_4 = 'https://aimoda-ai.oss-us-east-1.aliyuncs.com/aimoda-homepage-image/achievement-continents.svg'

const featureList = [
  {
    key: 0,
    title: '1000 +',
    content: 'Downloads per day',
    img: Group_1
  },
  {
    key: 1,
    title: '20 Thousand',
    content: 'Users',
    img: Group_2
  },
  {
    key: 2,
    title: '450 +',
    content: 'Clients',
    img: Group_3
  },
  {
    key: 3,
    title: '4',
    content: 'Continents',
    img: Group_4
  }
]

const RealStories = () => {
  return (
    <>
      {isBrowser && <Browse featureList={featureList} />}
      {isTablet && <Tablet featureList={featureList} />}
      {isMobile && !isTablet && <Mobile featureList={featureList} />}
    </>
  )
}

export default RealStories
