import React from 'react'
import styled from '@emotion/styled'
import { Button } from 'antd'

// import coverImg_1 from '../../../../../assets/img/coverImg_1.svg'
// import coverImg_2 from '../../../../../assets/img/coverImg_2.svg'
// import coverImg_3 from '../../../../../assets/img/coverImg_3.svg'
import videoIcon from '../../../../../assets/img/videoIcon.svg'

const Header = ({ handleStart }) => {
  const coverImg_1 = 'https://aimoda-ai.oss-us-east-1.aliyuncs.com/aimoda-homepage-image/Group_75.svg'
  // const coverImg_2 = 'https://aimoda-ai.oss-us-east-1.aliyuncs.com/aimoda-homepage-image/Group_76.svg'
  // const coverImg_3 = 'https://aimoda-ai.oss-us-east-1.aliyuncs.com/aimoda-homepage-image/Group_77.svg'

  return (
    <MobileContainer className="home-header-tablet">
      <MobileTitle>Creation at Fingertips</MobileTitle>
      <MobileDescription>Intelligently driven creations at your fingertips, creating an unprecedented clothing generating engine.</MobileDescription>
      <Operation>
        <StyledButtom type="primary" onClick={handleStart}>
          Get Started
        </StyledButtom>
        <StyledLink color="default" variant="link">
          <img src={videoIcon} alt="video icon" />
          Watch Video
        </StyledLink>
      </Operation>
      <MobileImgGroup>
        <div>
          <img src={coverImg_1} alt="default-img-1" />
        </div>
      </MobileImgGroup>
    </MobileContainer>
  )
}

const MobileContainer = styled.div`
  position: relative;
  padding: 0px 40px;
  padding-top: 40px;
  min-height: calc(100vh - 116px);
  height: calc(100vh - 116px);
`
const MobileTitle = styled.div`
  color: #fff;
  font-feature-settings: 'liga' off;
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 45px; /* 125% */
  letter-spacing: -0.9px;
  margin-bottom: 16px;
  padding-right: 140px;
`
const MobileDescription = styled.div`
  color: #fff;
  font-feature-settings: 'liga' off;
  margin-bottom: 32px;

  /* Body lg/Regular */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
`
const MobileImgGroup = styled.div`
  z-index: 1;
  position: relative;
  max-width: 100vw;
  min-height: 505px;

  & > div {
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scaleX(-1);

    & > img {
      width: 100%;
      height: 100%;
    }

    &:first-child {
      position: absolute;
      right: 3vw;
      z-index: 2;
    }
  }
`

const Operation = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 24px;
`
const StyledButtom = styled(Button)`
  width: 138px;
  height: 56px;
  border-radius: 4px;
  background: linear-gradient(135deg, #f70c18 0%, #cd071e 100%) !important;

  color: #fff;
  text-align: center;
  font-feature-settings: 'liga' off;
  /* Body md/SemiBold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */

  &:hover {
    background: linear-gradient(135deg, #f70c18 0%, #cd071e 100%) !important;
  }
`
const StyledLink = styled.div`
  width: 180px;
  height: 56px;
  color: #f70c18;
  text-align: center;
  font-feature-settings: 'liga' off;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  & > img {
    margin-right: 8px;
  }
`

export default Header
