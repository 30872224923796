import React from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'

import { isMobile, isTablet } from 'react-device-detect'

import Partner_1 from '../../../assets/img/Partners-1.png'
import Partner_2 from '../../../assets/img/Partners-2.png'
import Partner_3 from '../../../assets/img/Partners-3.png'
import Partner_4 from '../../../assets/img/Partners-4.png'
import Partner_5 from '../../../assets/img/Partners-5.png'

const imgList = [
  {
    key: 0,
    img: Partner_1
  },
  {
    key: 1,
    img: Partner_2
  },
  {
    key: 2,
    img: Partner_3
  },
  {
    key: 3,
    img: Partner_4
  },
  {
    key: 4,
    img: Partner_5
  }
]

const LogoCloud = () => {
  return (
    <Container isMobile={isMobile}>
      <SildeWrapper>
        <SlideDom isMobile={isMobile} isTablet={isTablet}>
          {imgList.concat(imgList, imgList, imgList).map((item, index) => (
            <ImgBox key={item.key + index}>
              <img src={item.img} alt={`partner_log_${item.key}`} />
            </ImgBox>
          ))}
        </SlideDom>
      </SildeWrapper>
    </Container>
  )
}

const rotate360 = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-100% + 100vw));
  }
`

const Container = styled.div`
  padding: ${props => (props.isMobile ? '16px 26px' : '0 114px')};
`
const SildeWrapper = styled.div`
  border-top: 1px solid #e5e7eb;
  border-bottom: 1px solid #e5e7eb;
  position: relative;
  overflow: hidden;

  &::before {
    left: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), rgb(255, 255, 255));

    position: absolute;
    top: 0;
    content: '';
    width: 175px;
    height: 100%;
    z-index: 2;
  }
  &::after {
    right: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(255, 255, 255));

    position: absolute;
    top: 0;
    content: '';
    width: 175px;
    height: 100%;
    z-index: 2;
  }
`
const SlideDom = styled.div`
  width: max-content;
  // animation: ${rotate360} ${props => (props.isMobile ? '8s' : props.isTablet ? '11s' : '15s')} infinite linear;
  animation: ${rotate360} 35s infinite linear;

  padding: 24px 0;
  display: flex;
  // width: 100%;

  &:hover {
    animation-play-state: paused;
  }
`
const ImgBox = styled.div`
  &:not(:last-child) {
    margin-right: 64px;
  }

  & img {
    height: 36px;
  }
`

export default LogoCloud
