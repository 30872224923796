import styled from '@emotion/styled'
import React from 'react'
import { isMobile, isTablet, isBrowser } from 'react-device-detect'

import BlackBackGround from '../components/BlackBackground'

const Pricing = () => {
  return (
    <Container isMobile={isMobile}>
      <BlackBackGround />
      <Wrapper>
        <Title isMobile={isMobile} isTablet={isTablet} isBrowser={isBrowser}>
          TBA
        </Title>

        <Description isTablet={isTablet} isBrowser={isBrowser}>
          Have fun and enjoy generating for now!
        </Description>
      </Wrapper>
    </Container>
  )
}
const Container = styled.div`
  height: calc(100vh - ${props => (props.isMobile ? '80px' : '116px')});
`
const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

const Title = styled.div`
  text-align: center;
  font-feature-settings: 'liga' off;
  font-family: Inter;
  font-size: ${props => (props.isBrowser ? '60px' : props.isTablet ? '40px' : '36px')};
  font-style: normal;
  font-weight: 600;
  line-height: 72px; /* 120% */
  letter-spacing: -1.5px;

  background: linear-gradient(135deg, #f70c18 0%, #cd071e 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;

  padding-top: ${props => (props.isMobile ? '40px' : '15vh')};
  margin-bottom: ${props => (props.isMobile ? '16px' : '24px')};
`
const Description = styled.div`
  color: #e9e9e9;
  text-align: center;
  font-feature-settings: 'liga' off;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
  max-width: ${props => (props.isTablet || props.isBrowser ? '70vw' : '200px')};
`

export default Pricing
