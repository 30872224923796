import React from 'react'
import styled from '@emotion/styled'
import { Button } from 'antd'

// import Slogan from '../../../../../assets/img/slogan.svg'
// import coverImg_1 from '../../../../../assets/img/coverImg_1.svg'
// import coverImg_2 from '../../../../../assets/img/coverImg_2.svg'
// import coverImg_3 from '../../../../../assets/img/coverImg_3.svg'
import videoIcon from '../../../../../assets/img/videoIcon.svg'

const Header = ({ handleStart }) => {
  const coverImg_1 = 'https://aimoda-ai.oss-us-east-1.aliyuncs.com/aimoda-homepage-image/Group_75.svg'
  // const coverImg_2 = 'https://aimoda-ai.oss-us-east-1.aliyuncs.com/aimoda-homepage-image/Group_76.svg'
  // const coverImg_3 = 'https://aimoda-ai.oss-us-east-1.aliyuncs.com/aimoda-homepage-image/Group_77.svg'

  return (
    <Container className="home-header-browse">
      <Basic>
        <Title>Creation at Fingertips</Title>
        <Description>
          <p>Intelligently driven creations at your fingertips, creating an upprecedtend cloting generative engine.</p>
        </Description>
        <Operation>
          <StyledButtom type="primary" onClick={handleStart}>
            Get Started
          </StyledButtom>
          <StyledLink color="default" variant="link">
            <img src={videoIcon} alt="video icon" />
            Watch Video
          </StyledLink>
        </Operation>
      </Basic>
      <ImgGroup>
        {/* <div>
          <img src={coverImg_2} alt="default-img-1" />
        </div> */}
        <div>
          <img src={coverImg_1} alt="default-img-2" />
        </div>
        {/* <div>
          <img src={coverImg_3} alt="default-img-3" />
        </div> */}
      </ImgGroup>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justifycontent: flex-start;
  min-height: calc(100vh - 116px);
  height: calc(100vh - 116px);
`

const Basic = styled.div`
  position: relative;
  z-index: 1;
  padding: 150px 30px 150px 100px;
  flex-shrink: 1;
  width: calc(100% - 560px);
  max-width: 1200px;
  box-sizing: border-box;

  & > div {
    margin-bottom: 36px;
    & > img {
      width: 100%;
    }
  }
`
const Title = styled.div`
  color: #fff;
  font-feature-settings: 'liga' off;
  font-family: 'Inter';
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: 72px; /* 120% */
  letter-spacing: -1.5px;
  text-transform: uppercase;
`
const Description = styled.div`
  p {
    align-self: stretch;
    color: #e0e0e0;
    font-feature-settings: 'liga' off;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 155.556% */
  }
`

const Operation = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 24px;
`
const StyledButtom = styled(Button)`
  width: 138px;
  height: 56px;
  border-radius: 4px;
  background: linear-gradient(135deg, #f70c18 0%, #cd071e 100%) !important;

  color: #fff;
  text-align: center;
  font-feature-settings: 'liga' off;
  /* Body md/SemiBold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */

  &:hover {
    background: linear-gradient(135deg, #f70c18 0%, #cd071e 100%) !important;
  }
`
const StyledLink = styled.div`
  width: 180px;
  height: 56px;
  color: #f70c18;
  text-align: center;
  font-feature-settings: 'liga' off;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  & > img {
    margin-right: 8px;
  }
`
const ImgGroup = styled.div`
  z-index: 1;
  position: relative;
  min-width: 560px;
  min-height: 660px;
  height: 100%;
  flex: 1;

  & > div {
    width: 50vh;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 8vw;
    z-index: 2;
    transform: scaleX(-1);

    & > img {
      width: 100%;
      height: 100%;
    }
  }
`

export default Header
