import React from 'react'
import styled from '@emotion/styled'

const WhatWeDo = ({ featureList }) => {
  return (
    <Container>
      <Title>What We Do</Title>
      <Description>Realizing fashion ambition with CREAMODA, from generative designs to quality retail-ready garments.</Description>
      <Wrapper>
        {featureList.map((feature, index) => (
          <Feature key={feature.title + index}>
            <FeaterImg>
              <img src={feature.img} alt={feature.title} />
            </FeaterImg>
            <FeatureTitle>{feature.title}</FeatureTitle>
            <FeatureContent>{feature.content}</FeatureContent>
          </Feature>
        ))}
      </Wrapper>
    </Container>
  )
}

const Container = styled.div`
  margin-top: 96px;
  padding: 0 112px;
`
const Title = styled.div`
  color: #111827;
  text-align: center;
  font-feature-settings: 'liga' off;
  /* Display lg / Bold */
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px; /* 125% */
  letter-spacing: -1.2px;
`
const Description = styled.div`
  color: #111827;
  text-align: center;
  font-feature-settings: 'liga' off;
  margin-bottom: 64px;

  /* Body lg / Regular */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
`

const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
`
const Feature = styled.div`
  width: calc(50% - 15px);
  margin-bottom: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:nth-child(odd) {
    margin-right: 30px;
  }
`
const FeaterImg = styled.div`
  text-align: center;
  margin-bottom: 24px;
  width: 100%;

  & > img {
    border-radius: 4px;
    width: 100%;
  }
`
const FeatureTitle = styled.div`
  color: #111827;
  text-align: center;
  font-feature-settings: 'liga' off;

  /* Display sm / Bold */
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 133.333% */
  margin-bottom: 8px;
`
const FeatureContent = styled.div`
  color: var(--Neutral-700, #374151);
  text-align: center;
  font-feature-settings: 'liga' off;

  /* Body md / Regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`

export default WhatWeDo
