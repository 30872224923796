import React from 'react'
import styled from '@emotion/styled'

const Thank = ({ featureList }) => {
  return (
    <Container>
      <Wrapper>
        <Box>
          <Title>Our Achievement</Title>
          <Description>With our super powers we can reach this</Description>
        </Box>
        <Box>
          <GroupBox>
            {featureList.map(feature => (
              <Feature key={feature.key}>
                <FeatuerImg>
                  <img src={feature.img} alt={feature.title} />
                </FeatuerImg>
                <div>
                  <FeatureTitle>{feature.title}</FeatureTitle>
                  <FeatureContent>{feature.content}</FeatureContent>
                </div>
              </Feature>
            ))}
          </GroupBox>
        </Box>
      </Wrapper>
    </Container>
  )
}

const Container = styled.div`
  margin-top: 48px;
  padding: 0 20px;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const Box = styled.div`
  width: 100%;
  margin-bottom: 48px;
  box-sizing: border-box;

  &:first-child {
    margin-right: 32px;
  }
`
const Title = styled.div`
  color: var(--Neutral-900, #111827);
  font-feature-settings: 'liga' off;

  /* Display lg / Bold */
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -1.2px;
  margin-bottom: 8px;
`
const Description = styled.div`
  color: var(--Neutral-900, #111827);
  font-feature-settings: 'liga' off;

  /* Body lg / Regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
`
const GroupBox = styled.div`
  display: flex;
  flex-flow: row wrap;
`
const Feature = styled.div`
  width: 100%;
  height: 69px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &:not(:last-child) {
    margin-bottom: 24px;
  }
`
const FeatuerImg = styled.div`
  padding: 8px;
  margin-right: 16px;
  height: 32px;
  width: 32px;

  & > img {
    width: 100%;
  }
`
const FeatureTitle = styled.div`
  color: var(--Neutral-900, #111827);
  font-feature-settings: 'liga' off;
  /* Display xs/Bold */
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
`
const FeatureContent = styled.div`
  color: var(--Neutral-900, #111827);
  font-feature-settings: 'liga' off;
  /* Body sm/Regular */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`

export default Thank
