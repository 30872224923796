import React from 'react'
import { isMobile, isTablet, isBrowser } from 'react-device-detect'

import Browse from './components/browse'
import Mobile from './components/mobile'

const Nav = () => {
  console.log(isMobile, isTablet, isBrowser)
  return (
    <>
      {isBrowser && <Browse />}
      {(isMobile || isTablet) && <Mobile isTablet={isTablet} />}
    </>
  )
}

export default Nav
