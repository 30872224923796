import React from 'react'

import { isMobile, isTablet, isBrowser } from 'react-device-detect'

import Browse from './components/browse'
import Tablet from './components/tablet'
import Mobile from './components/mobile'

const Header = ({ handleStart }) => {
  return (
    <>
      {isBrowser && <Browse handleStart={handleStart} />}
      {isTablet && <Tablet handleStart={handleStart} />}
      {isMobile && !isTablet && <Mobile handleStart={handleStart} />}
    </>
  )
}

export default Header
