import React from "react"
import styled from "@emotion/styled"

import { isBrowser } from "react-device-detect"

import QRCOdeImg from "../../../assets/img/QRCode.png"

const QRCodeBox = ({ visible, setVisible }) => {
  return (
    <>
      {visible ? (
        <Containter
          onClick={() => {
            setVisible(false)
          }}
        >
          <Wrapper isBrowser={isBrowser}>
            <Mask />
            <ImgContainer isBrowser={isBrowser}>
              <img src={QRCOdeImg} alt="CREAMODA qr code" />
            </ImgContainer>
          </Wrapper>
        </Containter>
      ) : null}
    </>
  )
}

const Containter = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
`
const Wrapper = styled.div``
const Mask = styled.div`
  background: rgba(0, 0, 0, 0.6);
  bottom: 0;
  height: 100%;
  left: 0;
  opacity: 1;
  pointer-events: all;
  position: absolute;
  right: 0;
  top: ${props => (props.isBrowser ? "116px" : "80px")};
  width: 100%;
  z-index: 0;
`
const ImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  outline: 0;
  pointer-events: all;
  position: absolute;

  z-index: 1;
  width: ${props => (props.isBrowser ? "30vw" : "75vw")};
  background: #fff;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  & > img {
    width: 100%;
  }
`

export default QRCodeBox
