import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'

/**
 * 需要上级节点的 position 为 relative
 * @returns
 */
const BlackBackGround = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth)

  useEffect(() => {
    // 定义一个防抖函数
    function debounce(func, wait) {
      let timeout
      return function executedFunction(...args) {
        const later = () => {
          clearTimeout(timeout)
          func(...args)
        }
        clearTimeout(timeout)
        timeout = setTimeout(later, wait)
      }
    }

    // 定义一个函数来处理 resize 事件
    function handleResize(event) {
      const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

      setWindowWidth(width)
    }

    // 创建一个防抖版本的 handleResize 函数
    const debouncedHandleResize = debounce(handleResize, 250)

    // 添加事件监听器
    window.addEventListener('resize', debouncedHandleResize)

    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  }, [])

  return <Background windowWidth={windowWidth} />
}

const Background = styled.div`
  z-index: 0;
  background: #000;
  position: absolute;
  height: 70vh;
  width: 100%;
  overflow-x: hidden;

  &::after {
    content: '';
    display: inline-block;
    height: 0;
    width: 0;
    border-top: 100px solid #000;
    border-left: ${props => props.windowWidth / 2 + 1}px solid #000;
    border-right: ${props => props.windowWidth / 2 + 1}px solid #fff;
    border-bottom: 100px solid #fff;
    bottom: -0;
    position: absolute;
  }
`

export default BlackBackGround
