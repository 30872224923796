import React from 'react'
import styled from '@emotion/styled'
import { NavLink } from 'react-router-dom'

// import Logo from '../../../assets/img/Logo.svg'

const Browse = () => {
  const LogoUrl = 'https://aimoda-ai.oss-us-east-1.aliyuncs.com/aimoda-homepage-image/AIMODA_small.svg'
  return (
    <Container className="nav-contaienr">
      {/* <img src={LogoUrl} alt="CREAMODA logo" /> */}
      <h1>CREAMODA</h1>
      <NavLink className={({ isActive }) => (isActive ? 'active-link' : 'inactive-link')} to="/">
        Features
      </NavLink>
      <NavLink className={({ isActive }) => (isActive ? 'active-link' : 'inactive-link')} to="/pricing">
        Pricing
      </NavLink>
      <NavLink className={({ isActive }) => (isActive ? 'active-link' : 'inactive-link')} to="/getintouch">
        Get In Touch
      </NavLink>
    </Container>
  )
}

const Container = styled.div`
  background: #000;
  height: 116px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 100px;
  position: sticky;
  top: 0;
  z-index: 100;

  img {
    margin-right: 36px;
  }

  h1 {
    color: #fff;
    margin-right: 36px;
    font-family: 'Inter';
  }

  a {
    color: #fff;
    text-decoration: unset;
    margin-right: 36px;
    position: relative;
  }

  .active-link {
    &::after {
      content: '';
      position: absolute;
      width: 120%;
      height: 4px;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      bottom: -8px;
      background: linear-gradient(135deg, #f70c18 0%, #cd071e 100%);
    }
  }
`

export default Browse
