import React from 'react'
import styled from '@emotion/styled'

// import QuoteMark from '../../../../../assets/img/Quote mark.svg'
import ContentQuoteMark from '../../../../../assets/img/contentQuoteMark.svg'

const QuoteMark = 'https://aimoda-ai.oss-us-east-1.aliyuncs.com/aimoda-homepage-image/bigquota.svg'

const RealStories = ({ featureList }) => {
  return (
    <Container>
      <Title>
        <img src={QuoteMark} alt="real_stories_quote_mark" />
        <span>Real Stories from Real Customers</span>
      </Title>
      <Description>Get inspired by these stories.</Description>
      <Wrapper>
        {featureList.map(feature => (
          <Feature key={feature.title} size={feature.type}>
            <FeaterImg>
              <img src={ContentQuoteMark} alt="contentQuoteMark" />
            </FeaterImg>
            <FeatureCommentary>{feature.commentary}</FeatureCommentary>
            <FeatureAuthor>
              <div>{feature.author}</div>
              <div>{feature.title}</div>
            </FeatureAuthor>
          </Feature>
        ))}
      </Wrapper>
    </Container>
  )
}

const Container = styled.div`
  margin-top: 96px;
  padding: 0 112px;
`
const Title = styled.div`
  color: #010101;
  font-feature-settings: 'liga' off;
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px; /* 125% */
  padding-left: 96px;
  letter-spacing: -1.2px;
  position: relative;

  & > img {
    position: absolute;
    top: -60px;
    left: -6px;
    opacity: 0.5;
  }
  & > span {
    z-index: 1;
    position: relative;
  }
`
const Description = styled.div`
  color: #010101;
  font-feature-settings: 'liga' off;

  /* Body lg / Regular */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
  padding-left: 96px;
  margin-bottom: 24px;
`
const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
`
const Feature = styled.div`
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 32px;
  padding-left: 64px;
  margin-bottom: 24px;
  width: ${props => (props.size === 'sm' ? 'calc(35% - 26px)' : 'calc(65% - 26px)')};

  position: relative;

  box-sizing: border-box;

  &:nth-child(odd) {
    margin-right: 50px;
  }
`
const FeaterImg = styled.div`
  position: absolute;
  left: 40px;
  top: 27px;

  img {
    width: 16px;
    height: 13px;
  }
`
const FeatureCommentary = styled.div`
  color: #111827;
  font-feature-settings: 'liga' off;

  /* Body lg / Regular */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
  margin-bottom: 24px;
`
const FeatureAuthor = styled.div`
  & > div:first-child {
    color: #111827;
    font-feature-settings: 'liga' off;

    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 155.556% */
  }
  & > div:last-child {
    color: #6b7280;
    font-feature-settings: 'liga' off;

    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
`

export default RealStories
