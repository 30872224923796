import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <Container>
      <div>
        <Logo>CREAMODA</Logo>
      </div>
      <Links>
        <Link to="/">Features</Link>
        <Link to="/pricing">Pricing</Link>
        <Link to="/getintouch">Contact</Link>
      </Links>
      <Right>© CREAMODA. All rights reserved</Right>
    </Container>
  )
}

const Container = styled.footer`
  width: 100%;
  height: 240px;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & > div:not(:last-child) {
    margin-bottom: 20px;
  }
`
const Links = styled.div`
  & > a {
    color: var(--White, #fff);
    font-feature-settings: 'liga' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-decoration: unset;
    &:not(:last-child) {
      margin-right: 24px;
    }
  }
`
const Right = styled.div`
  color: var(--Neutral-300, #d1d5db);
  font-feature-settings: 'liga' off;
  /* Body sm / Regular */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`

const Logo = styled.div`
  color: #fff;
  text-align: center;
  font-feature-settings: 'liga' off;
  font-family: 'Arial Black';
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 24px; /* 75% */
  letter-spacing: 2.56px;
`

export default Footer
